<template>
    <v-container class="p-relative">
        <v-row>
            <v-col cols="12" md="12" class="py-0">
                <RegistryHeader
                    :title="getWebSiteContactRegistry.name"
                    :avatarData="getWebSiteContactRegistry.currentMenager"
                    @clearSearch="clearTable"
                />
                <Table
                    :title="getWebSiteContactRegistry.name"
                    :items="getWebSiteContactTable.items"
                    :headers="headers"
                    :length="getWebSiteContactTable.pages"
                    :page="getWebSiteContactTable.page"
                    @search="setSearch"
                    @clear-table="clearTable"
                    @search-fields="setSearchFields"
                    @change-page="setPage"
                    @sort="setSort"
                    :defaultFields="getWebSiteContactSearchFields.searchFields"
                    :loading="getWebSiteContactTable.loading"
                >
                    <Columns
                        slot="columns"
                        :items="getWebSiteContactTable.items"
                    />
                </Table>
                <!-- <div class="d-flex justify-end buttons--action">
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                data-cy="addEntry"
                                fab
                                dark
                                v-on="on"
                                small
                                v-if="add()"
                                @click="addWebSiteContact()"
                                class="buttons--add margin--1"
                            >
                                <v-icon small>mdi-plus</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('webSiteContact:addWebSiteContact') }}</span>
                    </v-tooltip>
                </div> -->
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import store from './../../../store/index'
import Columns from '../../../components/Registries/WebSiteContact/Table/Columns'
export default {
    data() {
        return {
            open: false,
            headers: [
                {
                    text: this.$t('webSiteContact:index'),
                    value: 'index',
                    width: '10%',
                    showSearch: true,
                },
                {
                    text: this.$t('webSiteContact:creationDate'),
                    value: 'createdAt',
                    width: '9%',
                },
                {
                    text: this.$t('webSiteContact:contractor'),
                    value: 'contractor',
                    width: '20%',
                    showSearch: true,
                },
                {
                    text: this.$t('webSiteContact:titleAndContent'),
                    value: 'content',
                    width: 'auto',
                    showSearch: true,
                },
                {
                    text: this.$t('webSiteContact:responsible'),
                    value: 'responsible.name join responsible.lastname',
                    width: '16%',
                    showSearch: true,
                },
                {
                    text: this.$t('webSiteContact:status'),
                    value: 'status.name',
                    width: '8%',
                    showSearch: true,
                },
                {
                    text: '',
                    value: 'arrow',
                    align: 'right',
                    width: '6%',
                    sortable: false,
                },
            ],
        }
    },
    components: {
        Columns,
    },
    computed: {
        ...mapGetters([
            'getWebSiteContactRegistry',
            'getWebSiteContactTable',
            'getWebSiteContactSearchFields',
            'getSearch',
        ]),
    },
    methods: {
        ...mapActions(['fetchWebSiteContactTable', 'fetchUsers']),
        ...mapMutations([
            'clearWebSiteContactModal',
            'clearWebSiteContactErrors',
            'setWebSiteContactTable',
            'clearWebSiteContactTable',
            'setWebSiteContactSearch',
        ]),
        add() {
            return this.checkPermissions('CREATE')
        },
        closeModal() {
            this.open = false
        },
        async addWebSiteContact() {
            this.open = true
            this.clearWebSiteContactErrors()
            this.clearWebSiteContactModal()
        },
        setSearchFields(searchFields) {
            this.setWebSiteContactSearch(searchFields)
        },
        setSearch(search) {
            this.setWebSiteContactTable({ search, page: 1 })
            this.fetchWebSiteContactTable()
        },
        setPage(page) {
            console.log(page)
            this.setWebSiteContactTable({ page })
            this.fetchWebSiteContactTable()
        },
        setSort(sort) {
            this.setWebSiteContactTable({ sort })
            this.fetchWebSiteContactTable()
        },
        clearTable() {
            this.getSearch.text = ''
            this.clearWebSiteContactTable()
            this.fetchWebSiteContactTable()
        },
    },
    beforeRouteEnter(to, from, next) {
        if (from.matched[1] && from.matched[1].path !== '/webSiteContact/:id')
            store.commit('clearWebSiteContactTable')
        store.dispatch('fetchWebSiteContactTable').then(() => next())
    },
}
</script>
